import React from "react"

import { Link } from "gatsby"

//styling in header scss

const HeaderLogo = () => {

    return(
        <h1>
            <Link className="link__header__title header__logo" to="/">
            Galini
            </Link>
        </h1>
    )
}

export default HeaderLogo;