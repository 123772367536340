import React from "react"

const MobileMenuIcon = props => {
  return (
    <div
      className="u-display-none-desktop mobilemenuIcon"
    >
      <input type="checkbox" className="mobilemenuIcon__checkbox" id="nav-toggle" onClick={() => props.setSlideVisibility(!props.slideVisibility)} />
      <label htmlFor="nav-toggle" className="mobilemenuIcon__button">
        <span className="mobilemenuIcon__icon">&nbsp;</span>
      </label>
    </div>
  )
}

export default MobileMenuIcon
