import React from "react"
import { Link } from "gatsby"

import Button from '../buttons/button'

const MobileMenu = props => {
  return (
    <div
      className={
        props.slideVisibility
          ? "mobilemenu mobilemenu__onscreen"
          : "mobilemenu mobilemenu__offscreen"
      }
    >
<div className="mobilemenu__text">
        <nav className="mobilemenu__text__center">
            <ul className="mobilemenu__text__list">
              <li className="mobilemenu__text__list__item">
                <Link
                  to="/"
                  activeClassName="mobilemenu__text__list__item__link__active"
                  className="mobilemenu__text__list__item__link"
                >
                  Home
                </Link>
              </li>
              <li className="mobilemenu__text__list__item">
                <Link
                  to="/shop"
                  activeClassName="mobilemenu__text__list__item__link__active"
                  className="mobilemenu__text__list__item__link"
                >
                  Shop
                </Link>
              </li>
              <li className="mobilemenu__text__list__item">
                <Link
                  to="/learn/lemon-balm"
                  activeClassName="mobilemenu__text__list__item__link__active"
                  className="mobilemenu__text__list__item__link"
                >
                  Learn
                </Link>
              </li>
              {/* <li className="mobilemenu__text__list__item">

              <button className="snipcart-customer-signin mobilemenu__text__list__item__button">
              LogIn
            </button>
              </li> */}


              <li>
              <div className="u-margin-top-medium">

                <Button type="mobileMenu" text="Try the quiz" link="quiz" />
              </div>
            </li>

            </ul>
          </nav>

</div>

    </div>
  )
}

export default MobileMenu
