import React from "react"
import { navigate } from "gatsby"

const Button = props => {
  return (
      <button 
        className={`button__${props.type}`} 
        onClick={ () => {
          navigate(
            `/${props.link}`,
            {
              state: props.results
            }
            )
        }}>
          {props.text}
      </button>
  )
}

export default Button
