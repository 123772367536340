import React from "react"

import HeaderLogo from "../header/headerLogo"

const QuizHeader = props => {
  return (
    <div className="quizstick">
      <div className="u-standard-margins header">
        <HeaderLogo />
      </div>
    </div>

    // <div className="quiz__header">
    //   <HeaderLogo />
    //   {/*FOR DEBUGGGGGG <button onClick={props.printCheck}>check state</button> */}
    // </div>
  )
}

export default QuizHeader
