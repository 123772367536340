import React, { useState, useContext } from "react"

import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context"

import bagIcon from "../../../data/icons/shopping_bag.svg"

import Button from "../buttons/button"
import HeaderLogo from "./headerLogo"
import DesktopMenu from "./desktopMenu"
import MobileMenuIcon from "./mobileMenuIcon"
import MobileMenu from "./mobileMenu"

const Header = () => {

  const { state } = useContext(SnipcartContext)
  const [slideVisibility, setSlideVisibility] = useState(false);

  return (
    <header>
      <div className="fixed">
        <div className="u-standard-margins header">
          <HeaderLogo />
  
          <div className="header__left">
            
            {/* display none mobile */}
            <DesktopMenu />
  
            <div className="u-display-none-tablet">
              <Button type="header" text="Try the quiz" link="quiz" />
            </div>
  
            <div className="u-margin-left-small header__cart">
              <img
                src={bagIcon}
                className="snipcart-checkout header__cart__bagIcon"
                alt="cart icon"
              />
              <div className="header__cart__cartNumber">
                <span>{state.cartQuantity}</span>
              </div>
            </div>
  
            <button className="snipcart-customer-signin u-margin-left-medium header__menu__link header__login u-display-none-tablet">
              LogIn
            </button>
            
            
              <MobileMenuIcon 
                slideVisibility={slideVisibility}
                setSlideVisibility={setSlideVisibility}
              />
          
          </div>

          <MobileMenu 
            slideVisibility={slideVisibility}
            setSlideVisibility={setSlideVisibility}
          />
        
        </div>
      </div>
    </header>
  )
}

export default Header
