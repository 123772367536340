/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header/header"
import QuizHeader from "./quiz/quizheader"
import Footer from "./footer"

// import "./layout.css"
import "../sass/main.scss"

const Layout = ({ quizPage, yourboxPage, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          email
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata?.title || `Title`}</title>
        <meta name="description" content={data.siteMetadata?.description || `Description`} />

        <link href="https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"></link>
      </Helmet>
      { quizPage || yourboxPage ? <QuizHeader /> : <Header /> } 
      
      <div>
        <main>{children}</main>
        {!quizPage && !yourboxPage ? <Footer email={data.site.siteMetadata.email} /> : '' }
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
