import React from "react"

const Footer = props => {
  return (
    <footer className="u-standard-section-margins footer">
      <p className="footer__contact">Contact Us:
      <br/>
      <a href={`mailto:${props.email}`}>{props.email}</a>
      </p>
      <br/><br/>
      <p className="footer__details">
        No statements on this site have been evaluated by the Food and Drug
        Administration. This product is not intended to diagnose, treat, cure,
        or prevent any disease. All products are intended for use of occasional,
        non-disease states of anxiety and stress.
      </p>
      <br/>
      <p>© 2020 by Galini.</p>
    </footer>
  )
}

export default Footer
