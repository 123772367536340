import React from "react"
import { Link } from "gatsby"

const DesktopMenu = () => {

    return (
        <nav className="u-display-none-tablet">
            <ul className="header__menu u-margin-right-small">
              <li className="header__menu__item">
                <Link
                  activeClassName="link__header__active"
                  className="link__header header__menu__link"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="header__menu__item">
                <Link
                  activeClassName="link__header__active"
                  className="link__header header__menu__link"
                  to="/shop"
                >
                  Shop
                </Link>
              </li>
              <li className="header__menu__item">
                <Link
                  activeClassName="link__header__active"
                  className="link__header header__menu__link"
                  to="/learn/lemon-balm"
                >
                  Learn
                </Link>
              </li>
            </ul>
          </nav>
    )
}

export default DesktopMenu;